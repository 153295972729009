import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "@atlaskit/css-reset";
import "./App.css";
import PrivacyPolicy from "./components/main/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<PrivacyPolicy />} index />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
}

export default App;
